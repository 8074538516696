import React from 'react'

const Arrow = ({className,...props}) =>{
    return (<div>
    <div id="arrow" className={"button view flex-c-v flex " + className}>
        <svg width="3" height="7" viewBox="0 0 3 7" fill="none" xmlns="http://www.w3.org/2000/svg"  className="re move-s-r">
            <path d="M3 3.80322L-4.70644e-08 6.83431L2.17922e-07 0.772134L3 3.80322Z" fill="#485E4C" />
        </svg>

    </div>
</div>)
}

export default Arrow