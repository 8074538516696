import React from "react"

const BodyWrapper = ({ children, className, ...props }) => {
    return (
        <div className={" p-r-15  p-l-57 p-l-15-br flex flex-c  " + className} {...props}>
                {children}
                
        </div>
    )
}
export default BodyWrapper
