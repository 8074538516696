
const arrowActive  = (el) =>{
  
    el?.querySelector("#arrow")?.classList.add('active')
}
const arrowNActive = (element) =>{
   
    element?.querySelector("#arrow")?.classList.remove('active')
}

const scale =(el) =>{
  
    const list =  el.querySelectorAll('.enlarge')
    list.forEach((ele) =>{   
        ele.style.transition = ` transform 0.75s ease`
    })
    list.forEach((ele) =>{   
        ele.style.transform =  ` scale(1.01)`
    })
   
   }
   const scaleDown =(el) =>{
    const list =  el.querySelectorAll('.enlarge')
    list.forEach((ele) =>{   
        ele.style.transform =  ` scale(1.00)`
    })
   }

const addFilter = (id) => {
    document.querySelectorAll(`#${id}`).forEach((el) =>{
        arrowActive(el)
        el?.querySelector("[data-gatsby-image-wrapper]")?.classList.add("scale")
        el.querySelector("#overlay").classList.add("scale")
       
        el.querySelector("#overlay").classList.add("p-0")
      
       
    })
  
    document.querySelectorAll(`.wrapper:not(#${id})`).forEach(element => {
        element.querySelector("#overlay").classList.remove("p-0")
        element.querySelector('.image').classList.replace("p-1","p-0")
        arrowNActive(element)
        element.querySelector("[data-gatsby-image-wrapper]")?.classList.remove("scale")
        element.querySelector("#overlay").classList.remove("scale")
       
    });

}
const removeFilter = () =>{

    document.querySelectorAll(`.wrapper`).forEach(element => {
        element.querySelector("#overlay").classList.remove("p-0")
        element.querySelector("#overlay").classList.add("p-1")
        element.querySelector('.image').classList.replace("p-0","p-1")
        arrowNActive(element)
        element.querySelector("[data-gatsby-image-wrapper]")?.classList.remove("scale")
        element.querySelector("#overlay").classList.remove("scale")
        
    });
}

export {removeFilter}

export  {addFilter}

export {arrowNActive}
export {arrowActive}

export {scale}
export {scaleDown}