import React from 'react'

const SplitSectionHalf = ({ right, left,className,innerClassName,...props}) => {
    return (
        <div className={"p-t-15  " + className} {...props} >
            <div className={" b-t-grey-1 no-b-br p-t-15 p-b-15 h-100p p-t-0-br  "  + innerClassName}>
                <div className="flex flex-r flex-c-br h-100p flex-sb-br flex-grow-1">
                    <div className="w-50p-1 br">
                        {left}
                    </div>
                    <div className="w-1 bg-grey h-100a"></div>
                    <div className="w-50p-1 br">
                        {right}
                    </div>
                </div>

            </div>
        </div>

    )

}

export default SplitSectionHalf