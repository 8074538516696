import React from 'react'


const LineGen = ({ numberOfLines,className,...props }) => {
    const lines = []
    for (let i = 0; i < numberOfLines; i++) {
        lines.push(
            <div className="m-b-5 m-t-5 bg-45 h-1" key={i}>
            </div>
        )
    }
    return (
        <div className={"m-b-neg-5 m-t-neg-5 " + className} {...props}>
            {lines}
        </div>
    )
}

export default LineGen