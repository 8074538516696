import { useStaticQuery, graphql } from 'gatsby'

const useProducts = () => {
  const products = useStaticQuery(graphql`
query MyQuery2 {
 products: allDatoCmsProduct {
    nodes {
      name
      slug
      images {
        gatsbyImageData( placeholder: DOMINANT_COLOR)
        url
      }
      heroimage{
        gatsbyImageData( placeholder: DOMINANT_COLOR)
        url
      }
    }
  }
}
`)
  return products
}

export default useProducts