import React, { useContext, useEffect } from 'react'
import { MenuContextController } from '../../Context/menu'
import Lines from './Lines'

const Loading = () => {

    const { menu } = useContext(MenuContextController)

    useEffect(() => {
        if (!menu.load) {

            setTimeout(() => {
                document.querySelectorAll('.fade-in-text').forEach((el) => {
                    el.classList.replace('p-0', 'p-1')
                })
            }, 250)
            setTimeout(() => {
              
                document.querySelector('#menu').classList.add("tran--100p")

                document.querySelectorAll("[data-line]").forEach((el) => {
                    el.classList.replace("h-0-l", "h-100p-l")
                })
                document.querySelectorAll('[data-line-top]').forEach((el) => {
                    el.classList.replace("h-0-l", "h-100p-l")
                })


                setTimeout(() => {
                    document.querySelector('[data-loader-cream]').classList.replace("h-100vh", "h-0-l")

                    setTimeout(() => {
                        setTimeout(() => {

                            document.querySelector(`#menu`).classList.remove("tran--100p")
                            document.querySelector('#loading-wrapper').classList.remove('w-100vw-br')
                            setTimeout(() => {
                                setTimeout(() => {

                                    document.querySelectorAll('[data-loader]').forEach((el) => {
                                        el.classList.add('n-e')
                                    })
                                    setTimeout(() => {
                                        document.querySelectorAll('[data-line]').forEach((el) => {
                                            el.classList.add('p-0')
                                        })
                                    }, 1000)
                                }, 1000)
                            }, 500)
                        }, 500)

                    }, 500)
                }, 1000)
               

            }, 2000)

        }
    }, [menu.load])

    return (
        <>
            <div id="loading-wrapper" className="h-100vh t-0 al-e fi z-100 flex-br oh-br w-100vw-br">
                <div className={"p-1 fade  back-white-off h-100vh  w-100vw z-100 ab flex al-e flex-c b-0 o-h relative-br "} data-loader-cream data-loader id="loader">
                    <div className='w-100vw h-100vh  flex al-e flex-c r-br ab-br'>
                        <Lines color={"background-grey"} text={true} />
                    </div>
                </div>
            </div>

        </>)

}



export default Loading