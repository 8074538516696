import React, { useContext } from 'react'
import { MobileContext } from '../../Context/mobile'

const Lines = ({ color, text = true }) => {
    const { mobile } = useContext(MobileContext)
    return (
        <div className="flex h-50vh flex-e flex b-0 flex-r m-b-25vh p-30-br flex-c-br mt-a-br mb-a-br h-75-br" >
            <div className="flex-grow-1" data-section>

            </div>
            <div className={"w-40  h-tran  fade " + color + ((color === "background-white") ? " h-100p-l " : " h-0-l ")} data-line >

            </div>
            <div  className={"flex-grow-2  flex-c-h flex-c-v flex center-v  re w-tran tran-1 h-100p-l o-h p-0 " + (text ? 'fade-in-text' : '')}   >
                <div className={'background-white h-0-l ab t-0 w-100p z-3 h-tran '} data-line-top />
                <div className='z-2'>
                    <div className='h-100p-l flex flex-c flex-c-h '>
                        <svg width="75px" className="" viewBox="0 0 55 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.11555 9.63792C8.61665 11.1704 8.84128 11.4525 9.49646 11.5407L9.49646 11.9991L6.04346 11.9991L6.04346 11.5407C6.97655 11.5231 7.37254 11.3115 7.37254 10.712C7.37254 10.4475 7.30342 10.0949 7.1479 9.63645L6.54313 7.83946L2.57319 7.83946L2.02025 9.56592C1.88201 10.0067 1.79561 10.377 1.79561 10.64C1.79561 11.2571 2.19304 11.504 3.21108 11.5392L3.21108 11.9977L-4.36174e-06 11.9977L-4.19501e-06 11.5392C0.552936 11.4687 0.863966 11.0279 1.34635 9.53066L4.19601 0.757301L3.9253 0.00500488L4.90879 0.00500488L8.11555 9.63792ZM2.76326 7.27671L6.35449 7.27671L4.5416 1.81522L2.76326 7.27671Z" fill="#3F3D3D" />
                            <path d="M23.4989 0.019043L23.6544 3.05027L23.136 3.05027C22.8941 1.02406 22.5312 0.512737 20.5801 0.512737L18.5598 0.512737L18.5598 5.64069L20.1827 5.64069C21.4599 5.64069 21.6673 5.41148 21.8055 3.75554L22.3239 3.75554L22.3239 8.01953L21.8055 8.01953C21.6673 6.3636 21.4599 6.13439 20.1827 6.13439L18.5598 6.13439L18.5598 10.0105C18.5598 11.3314 18.9573 11.5077 20.4591 11.543L20.4591 12.0014L16.4014 12.0014L16.4014 11.543C17.454 11.5253 17.6268 11.3138 17.6268 10.1868L17.6268 1.83366C17.6268 0.706688 17.454 0.495105 16.4014 0.477473L16.4014 0.019043L23.4989 0.019043Z" fill="#3F3D3D" />
                            <path d="M53.4478 10.3073C54.0007 11.2418 54.3233 11.4519 54.5998 11.5225L54.5998 11.9809L53.1541 11.9809L49.6665 6.11387L48.1819 6.11387L48.1819 9.98996C48.1819 11.2404 48.3893 11.4872 49.39 11.5225L49.39 11.9809L46.0234 11.9809L46.0234 11.5225C47.0775 11.5048 47.2488 11.2933 47.2488 10.1663L47.2488 1.81462C47.2488 0.687645 47.076 0.476062 46.0234 0.45843L46.0234 0L49.943 0C52.1706 0 53.2405 1.00502 53.2405 2.78438C53.2405 4.25223 52.3779 5.69658 50.7018 6.03159L53.4478 10.3073ZM48.1819 0.493694L48.1819 5.62165L49.6665 5.62165C51.3584 5.62165 52.136 4.28309 52.136 2.83727C52.136 1.28713 51.514 0.493694 49.8911 0.493694L48.1819 0.493694Z" fill="#3F3D3D" />
                            <path d="M37.8102 0.019043L31.8704 0.019043L30.6824 0.019043L30.5586 3.22512L31.077 3.22512C31.4226 0.846275 31.699 0.5289 33.391 0.5289L34.3759 0.5289L34.3759 9.35662C34.3759 11.0831 34.1685 11.5062 32.684 11.5415L32.684 11.9999L36.9836 11.9999L36.9836 11.5415C35.5163 11.5062 35.309 11.0831 35.309 9.35662L35.309 0.5289L36.4653 0.5289C38.0363 0.5289 38.2954 0.846275 38.6065 3.22512L39.1249 3.22512L39.001 0.019043L37.8102 0.019043Z" fill="#3F3D3D" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className={"w-5  h-tran  fade " + color + ((color === "background-white") ? " h-100p-l " : " h-0-l ")} data-line  >
            </div>
            <div className={"flex-grow-2  flex-c-h flex-c-v flex center-v w-tran re h-100p-l o-h re p-0 tran-1 " + (text ? 'fade-in-text' : '')} id="s-c" >
                <div className={'background-white h-0-l ab t-0 w-100p z-3 h-tran '} data-line-top />
                <div className='z-2'>
                    <div className='h-100p-l flex flex-c flex-c-h '>
                        <h2 className='match-logo'>Timeless by Design</h2>
                    </div>
                </div>

            </div>
            <div className="w-40 flex center-h m-w100p m-flex-start">
                <div className={"w-1-l  h-tran fade  " + color + ((color === "background-white") ? " h-100p-l " : " h-0-l ")} data-line>

                </div>
            </div>

            <div className={(mobile ? "flex-grow-1" : "flex-grow-1-18")} data-section>

            </div>
        </div>
    )
}

export default Lines