import React, { useContext, useEffect, useRef } from 'react'
import LocomotiveScroll from 'locomotive-scroll';
import { MobileContext } from '../../Context/mobile';

const Scroll = ({ children, location }) => {
    const { mobile } = useContext(MobileContext)
    const locoScroll = useRef()

    useEffect(() => {
      
        const scroll = new LocomotiveScroll({
            el: locoScroll.current,
            smooth: true,
            resetNativeScroll: (mobile ? true : false),
            reloadOnContextChange: true,
            tablet: { breakpoint: '820px' }

        });
        if(mobile){
            scroll.destroy()
        }else{
            setTimeout(() =>{
                scroll.update()
            },300)

        }

        
        window.addEventListener('resize', scroll.update() );
      
        
        scroll.on('call', (eventName, entryMode, element) => {

            if (element) {
                switch (eventName) {
                    case 'fadeInText':
                        if (entryMode === "enter") {
                            element.target.querySelector(`.fade`).classList.replace("p-0", "p-1")

                        } else {
                            element.target.querySelector(`.fade`).classList.replace("p-1", "p-0")
                        }
                        break;

                    case 'update':
                        setTimeout(() => {

                            scroll.update()
                        }, 300)
                        break;

                    default:
                        break;
                }
            }
        })
        return () =>{
            window.removeEventListener('resize', scroll.update() );
            
                scroll.destroy()
           

        }
        
    }, [location,mobile])

    return (<div data-scroll-container ref={locoScroll}>
        {children}
    </div>)
}

export default Scroll