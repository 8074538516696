
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React, { useContext, useMemo } from "react"
import { MenuContextController } from "../../Context/menu"
import SplitSectionHalf from "../../Layouts/SplitSectionHalf"
import ButtonHoverNone from "../../ResuableComponents/buttonNotHover"
import LineGen from "../../ResuableComponents/LineGen/LineGen"
import { arrowActive, arrowNActive, scale, scaleDown } from "../../Functions/fadeTransition"
import useMenuItems from "../../CustomHooks/useMenuItems"
import ChangePage from "../../Context/ChangePage"
import { MobileContext } from "../../Context/mobile"
import { graphql, useStaticQuery } from "gatsby"

const Footer = ({ location }) => {

    const { mobile } = useContext(MobileContext)
    const { setMenu } = useContext(MenuContextController)
    const menuItems = useMenuItems()

    const handleHover = (index) => {
        document.querySelectorAll(`.images:not(page${index})`).forEach((el) => {

            el.classList.replace("p-1", "p-0")
        })
        document.querySelector(`#page${index}`).classList.replace("p-0", "p-1")
    }

    const handleHoverOut = () => {
        document.querySelectorAll(`.images`).forEach((el) => {

            el.classList.replace("p-1", "p-0")
        })
    }
    const openContact = () => {
        if (mobile) {
            setMenu({ type: 'enableContact' });
        }
        setMenu({ type: "openSecondary", payload: { menu: true, secondary: false } })
    }
    const hoverButton = (el) => {
        el.querySelector(".no-hover").classList.add("hover")
    }
    const hoverOut = (el) => {
        el.querySelector(".no-hover").classList.remove("hover")
    }

    const menuList = menuItems.map(({ name, slug, id }, index) => {
        if (name !== "contact") {
            return (
                <ChangePage key={index} to={slug} onMouseEnter={() => { handleHover(index) }} onMouseLeave={() => { handleHoverOut() }} className={index !== 0 ? " m-t-6-br" : ""}>
                    <div className='flex flex-r flex-c-v'>
                        {location.pathname === slug ? <div>
                            <svg width="3" height="8" viewBox="0 0 3 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-r-15">
                                <path d="M3 4.34058L-4.70644e-08 7.83703L2.17922e-07 0.844125L3 4.34058Z" fill="#485E4C" />
                            </svg>
                        </div> : null}
                        <h4 className="l-14   uppercase">
                            {name}
                        </h4>
                    </div>
                </ChangePage>
            )
        } else {
            return (<button key={index} onMouseEnter={() => { handleHover(index) }} onMouseLeave={() => { handleHoverOut() }} onClick={() => { openContact() }}>
                <div className='flex flex-r flex-c-v m-t-6-br'>
                    {location.pathname === slug ? <div>
                        <svg width="3" height="8" viewBox="0 0 3 8" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-r-15">
                            <path d="M3 4.34058L-4.70644e-08 7.83703L2.17922e-07 0.844125L3 4.34058Z" fill="#485E4C" />
                        </svg>
                    </div> : null}
                    <h4 className="l-14   uppercase">
                        {name}
                    </h4>
                </div>
            </button>)
        }
    })

    const { products } = useStaticQuery(graphql`
        query ProductsQuery {
            products:allDatoCmsProduct(limit: 10) {
                nodes {
                    heroimage {
                        gatsbyImageData
                    }
                }
            }
        }
    `)



    const images = useMemo(() => {
        return products.nodes.map((product) => {
            return product.heroimage
        })
    })

 

    return (
        <div className="m-b-15 ">
            <LineGen numberOfLines={mobile ? 10 : 24} />
            <SplitSectionHalf className=""
                right={
                    <div className="p-l-15 p-l-15-br-0 "  >
                        <a href="https://instagram.com/aftr.studio" target="_blank" rel="noreferrer noopener" onMouseEnter={(e) => { arrowActive(e.target); scale(e.target); hoverButton(e.target); }} onMouseLeave={(e) => { arrowNActive(e.target); scaleDown(e.target); hoverOut(e.target) }} >
                            <div className="p-e-n">
                                <div className="flex flex-r flex-sb  m-b-15 m-b-15-br-s ">
                                    <div className="o-1 " >
                                        <ButtonHoverNone name="VIEW Instagram" className="m-b-15 h5" />
                                    </div>
                                    {mobile ? <h5 className="0-2">
                                        @AFTR.STUDIO
                                    </h5> : null}
                                </div>
                                <div className="flex flex-r flex-sb ">
                                    {images.slice(0, 3).map((image) => {
                                        return (
                                            <div className="w-33p p-t-50p re">
                                                <div className="ab h-100p w-100p t-0  ">
                                                    <GatsbyImage
                                                        className="h-100p"
                                                        alt=""
                                                        aspectRatio={3 / 3}
                                                        layout="fullWidth"
                                                        objectFit="cover"
                                                        objectPosition='center center'
                                                        imgClassName="enlarge"
                                                        image={image.gatsbyImageData}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </a>
                    </div>
                }
                left={
                    <div className="flex flex-sb flex-c h-100p flex-r-br">

                        <h3 className="m-b-110-br">
                            Follow
                        </h3>
                        {!mobile ? <h5>
                            @AFTR.STUDIO
                        </h5> : null}
                    </div>
                }
            />
            <LineGen numberOfLines={mobile ? 10 : 24} />
            <div className="flex flex-r flex-c-br">
                <div className="flex flex-c w-50p o-2 w-100p-br">
                    <div className="m-t-5">  <LineGen numberOfLines={mobile ? 24 : 30} /></div>
                    <div className="flex flex-r w-100p-br">
                        <div className="flex flex-c p-t-5 p-t-0-br al-e flex-sb flex-r-br w-100p-br" >
                            <div className="d-n-br">
                                <LineGen numberOfLines={1} className="d-n-br" />
                            </div>
                            <div className={"m-b--48px  w-100p-br flex-sb flex p-t-15-br-s p-r-15 p-r-0-br " + (mobile ? "flex-r" : 'flex-c')}>
                                <div>
                                    <h5 className="p-r-15 flex-r-br ">
                                        <span>©AFTR 2022</span>
                                    </h5>
                                </div>
                                <div className=''>
                                    <a href="https://groundcrew.com.au/" target="_blank" rel="noreferrer noopener">
                                        <h5 className="p-r-15 flex-r-br p-r-15-br-u">
                                            <span>Site by GROUNDCREW</span>
                                        </h5>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className=" flex-grow-1 m-t-5 d-n-br"> <LineGen numberOfLines={8} /></div>
                    </div>
                </div>
                <div className="w-50p flex flex-r o-1 w-100p-br " id="bottomSection">
                    <div className="flex m-b--48px flex-c al-e p-l-15 p-l-15-br-0 m-t-130-br m-b-10-br-s ">
                        {menuList}

                    </div>
                    <div className="h-100p  flex-grow-1 p-t-15 re">
                        <div className="re h-100p flex flex-r al-e m-w- ">
                            <div className="h-100p p-r-25p w-100vw m-w-150 re">
                                <div className={"ab images t-0 p-0  h-100p w-100p"} id={'page0'}>
                                    <StaticImage src="../../../images/home.png" className={"ab images t-0  h-100p w-100p"} alt="" />
                                </div>
                                <div className={"ab images t-0 p-0  h-100p w-100p"} id={'page1'}>
                                    <StaticImage src="../../../images/stone.jpg" className={"ab images t-0  h-100p w-100p"} alt="" />
                                </div>
                                <div className={"ab images t-0 p-0  h-100p w-100p"} id={'page2'}>
                                    <StaticImage src="../../../images/studio.png" className={"ab images t-0  h-100p w-100p"} alt="" />
                                </div>
                                <div className={"ab images t-0 p-0  h-100p w-100p"} id={'page3'}>
                                    <StaticImage src="../../../images/home.png" className={"ab images t-0  h-100p w-100p"} alt="" />
                                </div>
                                <div className={"ab images t-0 p-0  h-100p w-100p"} id={'page4'}>
                                    <StaticImage src="../../../images/home.png" className={"ab images t-0  h-100p w-100p"} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Footer




