import React from 'react'

import ButtonHoverNone from '../buttonNotHover'

const Details = () => {

    return (
        <div className="b-30 z-50 ">
            <div className="flex flex-c">
                <div className="flex flex-r m-b-25">
                    <div className='flex flex-c al-e'>

                        <h5>E.</h5>
                    </div>
                    <div className='flex flex-c m-l-15'>
                        <div className='flex flex-r m-b-8'><h5>Details</h5> </div>
                        <div className=' m-b-3'><a href="mailto:hello@aftrstudio.com" className="flex flex-r"><ButtonHoverNone name="hello@aftrstudio.com" /> </a></div>

                    </div>
                </div>
                <div className="flex flex-r">
                    <div className='flex flex-c al-e'>

                        <h5>I.</h5>
                    </div>
                    <div className='flex flex-c m-l-15'>
                        <div className='flex flex-r m-b-8'><h5>Follow</h5> </div>

                        <div className='flex flex-r'><a className="flex flex-r" href="https://www.instagram.com/aftr.studio" aria-label="link"><ButtonHoverNone name="@AFTR" /></a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details