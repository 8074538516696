import React, { useState, useEffect, useContext } from "react"

import ButtonHoverNone from "../buttonNotHover"
import LineGen from "../LineGen/LineGen"
import { MenuContextController } from "../../Context/menu"
import useProducts from "../../CustomHooks/fetchProducts"
import useMenuItems from "../../CustomHooks/useMenuItems"
import Details from "../Details/index"
import { navigate } from "gatsby"

import Mobile, { MobileContext } from "../../Context/mobile"

const Menu = ({ path }) => {
  const { menu, setMenu } = useContext(MenuContextController)
  const { mobile } = useContext(MobileContext)
  const [resize, setResize] = useState(null)
  const [trans, setTrans] = useState(null)
  useEffect(() => {
    if (!mobile) {
      window.addEventListener("resize", () => {
        if (resize) {
          clearTimeout(resize)
        }
        setTimeout(() => {
          document.querySelector("#undo-transition").style.transition = "none"
        }, 0)
        setResize(
          setTimeout(() => {
            document.querySelector("#undo-transition").style.transition = `transform 0.75s ease`
          }, 100)
        )
      })
    }
    return () => {
      if (!mobile) {
        window.addEventListener("resize", () => {
          if (resize) {
            clearTimeout(resize)
          }
          setTimeout(() => {
            document.querySelector("#undo-transition").style.transition = "none"
          }, 0)
          setResize(
            setTimeout(() => {
              document.querySelector("#undo-transition").style.transition = `transform 0.75s ease`
            }, 100)
          )
        })
      }
    }
  }, [mobile])

  const [fadeMenuContent, setFadeMenuContent] = useState(true)

  //form submit
  const handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("form1")
    let formData = new FormData(myForm)
    const dataName = formData.get("name"),
      type = formData.get("EnquiryType"),
      email = formData.get("email"),
      phone = formData.get("phone")
    if (dataName && type && email && phone) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          setMenu({ type: "formSubmitted" })
        })
        .catch(error => alert(error))
    }
  }

  useEffect(() => {
    if (!mobile) {
      setMenu({ type: "buttonPressed", payload: { bodyFade: false } })
      setMenu({ type: "closeMenu", payload: { secondary: false, menu: false } })
    } else {
      setMenu({ type: "buttonPressed", payload: { bodyFade: false } })
      setMenu({ type: "closeMenu", payload: { secondary: false, menu: false } })
    }
  }, [mobile, setMenu])

  useEffect(() => {
    setTimeout(() => {
      document?.querySelector("#menu-content")?.classList?.add("p-0")
      document?.querySelector("#menu-content")?.classList?.add("p-e-n")
    }, 500)
  }, [])

  const nav = slug => {
    if (slug !== path.pathname) {
      setMenu({ type: "loadMenu" })

      setMenu({ type: "buttonPressed", payload: { bodyFade: true } })
      setMenu({ type: "buttonActive", payload: { buttonActive: true } })
      setMenu({ type: "setCurrentPage", payload: { currentPage: slug } })

      if (!mobile) {
        if (menu.secondaryMenuOpen) {
          setTimeout(() => {
            navigate(slug, { state: { internal: path } })
          }, 800)
        } else {
          setTimeout(() => {
            navigate(slug, { state: { internal: path } })
          }, 400)
        }
      } else {
        setTimeout(() => {
          navigate(slug, { state: { internal: path } })
        }, 400)
      }
    }
  }

  const closeSecondary = (prevent, e) => {
    if (prevent) {
      e.preventDefault()
    }
    if (!mobile) {
      if (menu.secondaryMenuOpen === true) {
        setMenu({ type: "closeSecond" })

        setTimeout(() => {
          setMenu({ type: "closeMenu", payload: { menu: false, secondary: false } })
          setMenu({ type: "closeForm" })
        }, 750)
      } else {
        setMenu({ type: "closeMenu", payload: { menu: false, secondary: false } })

        setTimeout(() => {
          setMenu({ type: "closeForm" })
        }, 250)
      }
    } else {
      if (menu.secondaryMenuOpen) {
        setMenu({ type: "closeMenu", payload: { menu: false, secondary: true } })
        setTimeout(() => {
          setMenu({ type: "enableContact" })
          setMenu({ type: "closeMenu", payload: { menu: false, secondary: false } })
          setMenu({ type: "closeForm" })
        }, 1000)
      } else {
        setMenu({ type: "closeMenu", payload: { menu: false, secondary: false } })
        setTimeout(() => {
          setMenu({ type: "closeForm" })
        }, 250)
      }
    }
  }
  useEffect(() => {
    if (menu.menuOpen === true) {
      document?.querySelector("#menu-container")?.classList?.add("p-0")
      document?.querySelector("#menu-container")?.classList?.add("p-e-n")
      if (mobile) {
        setTimeout(() => {
          document?.querySelector("#menu-content")?.classList?.remove("p-0")
          document?.querySelector("#menu-content")?.classList?.remove("p-e-n")
        }, 1400)
      } else {
        if (!menu.secondaryMenuOpen) {
          setTimeout(() => {
            document?.querySelector("#menu-content")?.classList?.remove("p-0")
            document?.querySelector("#menu-content")?.classList?.remove("p-e-n")
          }, 1000)
        } else {
          document?.querySelector("#menu-content")?.classList?.remove("p-0")
          document?.querySelector("#menu-content")?.classList?.remove("p-e-n")
        }
      }
    } else {
      setTimeout(() => {
        document?.querySelector("#menu-container")?.classList?.remove("p-0")
        document?.querySelector("#menu-container")?.classList?.remove("p-e-n")
      }, 500)
      document?.querySelector("#menu-content")?.classList?.add("p-0")
      document?.querySelector("#menu-content")?.classList?.add("p-e-n")

      setTimeout(() => {
        document?.querySelector("#menu-content")?.classList?.add("p-0")
        document?.querySelector("#menu-content")?.classList?.add("p-e-n")
      }, 1400)
    }
  }, [menu.menuOpen])

  const FadeContentMobile = () => {
    if (mobile) {
      setMenu({ type: "enableContact" })
      setFadeMenuContent(false)

      setTimeout(() => {
        setFadeMenuContent(true)
        setMenu({ type: "buttonActive", payload: { buttonActive: false } })
        setMenu({ type: "openSecondary", payload: { menu: true, secondary: false } })
      }, 1000)
    } else {
      setMenu({ type: "openSecondary", payload: { menu: true, secondary: false } })
    }
  }

  const productsList = useProducts().products.nodes.map((product, index) => {
    return (
      <option key={index} value={product.name}>
        {product.name}
      </option>
    )
  })

  const Links = useMenuItems().map(({ name, slug }, index) => {
    if (name !== "contact") {
      return (
        <button
          onClick={() => {
            nav(slug)
          }}
          key={index}
        >
          <div className='flex re flex-c uppercase  '>
            <div className={"flex flex-r  p-t-12  " + (!mobile ? "p-l-15" : "")}>
              <div className={"flex center-v  m-t-a m-b-a " + (!(menu.secondaryMenuOpen || menu.enableContact) && (menu.currentPage === slug || (menu.currentPage === undefined && path?.pathname === slug)) ? "p-1 fade-in" : "p-0 fade-out")}>
                <svg width='3' height='8' viewBox='0 0 3 8' fill='none' xmlns='http://www.w3.org/2000/svg' className='m-r-15 ab'>
                  <path d='M3 4.34058L-4.70644e-08 7.83703L2.17922e-07 0.844125L3 4.34058Z' fill='#485E4C' />
                </svg>
              </div>
              <div className={"l-14  tran-m  " + (menu.currentPage === `${slug}` && menu.secondaryMenuOpen ? "grey " : "") + (!(menu.secondaryMenuOpen || menu.enableContact) && (menu.currentPage === slug || (menu.currentPage === undefined && path?.pathname === slug)) ? "m-l-15" : "")}>
                <h5> {name}</h5>
              </div>
            </div>
          </div>
        </button>
      )
    } else {
      return (
        <button
          onClick={() => {
            FadeContentMobile()
          }}
          key={index}
        >
          <div className='flex re flex-c uppercase '>
            <div className={"flex flex-r  p-t-12 " + (!mobile ? "p-l-15" : "")}>
              <div className={"flex center-v  m-t-a m-b-a  " + (menu.secondaryMenuOpen || menu.enableContact ? "p-1 fade-in" : "p-0 fade-out")}>
                <svg width='3' height='8' viewBox='0 0 3 8' fill='none' xmlns='http://www.w3.org/2000/svg' className='m-r-15 ab'>
                  <path d='M3 4.34058L-4.70644e-08 7.83703L2.17922e-07 0.844125L3 4.34058Z' fill='#485E4C' />
                </svg>
              </div>
              <div className={"l-14   tran-m  " + (menu.secondaryMenuOpen || menu.enableContact ? "m-l-15 " : "")}>
                <h5> {name}</h5>
              </div>
            </div>
          </div>
        </button>
      )
    }
  })

  const MenuContent = () => {
    return (
      <div className={"m-t-40vh  m-t-30vh-br flex flex-sb flex-c flex-grow-1 p-b-30 p-b-60-br  " + (mobile ? (!menu.secondaryMenuOpen ? "p-1" : "p-0") : "")}>
        <div className={"flex flex-c  w-100p  flex flex-c-h z-49 p-t-15 p-b-15" + (mobile ? "p-r-15 p-l-15" : "")}>
          <div className='flex flex-c w-100p'>{Links}</div>
        </div>
        <div className='p-l-15 p-b-60-br p-t-15-br'>
          <div className='m-b-30 p-r-15'>
            <LineGen numberOfLines={16} />
          </div>
          <Details />
        </div>
      </div>
    )
  }

  const FormContent = () => {
    const Text = () => {
      return (
        <>
          <h5 className='l-14 uppercase m-b-5 m-b-15-br-s f-15'>Enquiries</h5>
          <p>For all general and sales enquiries, please use the form provided and one of our dedicated team members will be in touch with you shortly.</p>
        </>
      )
    }

    return (
      <div className={"m-w-410  o-s   w-100vw-br  br-410 b-cream flex-grow-1 z-49  flex-c flex m-h-100vh-br  b-r-grey p-r-30 p-l-30 r p-l-15-br p-r-15-br ab w-100vw  h-100vh p-b-30 p-b-120-br " + (!menu.secondaryMenuOpen ? "tran-0 " : !mobile ? "tran-100p-100 " : "p-t-55 h-100vh flex-sb p-b-30 ") + (!menu.menuOpen ? " tran-an-1 " : "tran-an ") + (mobile ? "p-t-55 " : "") + (mobile ? (menu.secondaryMenuOpen ? "p-1 ab h-100vh t-0 " : "p-0") : "")}>
        <div className={"flex flex-c  p-r-30 p-t-15  " + (!mobile ? "ab" : "")}>
          {mobile ? (
            <button
              className='flex flex-r al-c-i m-b-15 '
              onClick={() => {
                FadeContentMobile()
              }}
            >
              <svg width='3' height='8' viewBox='0 0 3 8' fill='none' xmlns='http://www.w3.org/2000/svg' className='m-r-15 ' style={{ transform: `rotateZ(180deg)` }}>
                <path d='M3 4.34058L-4.70644e-08 7.83703L2.17922e-07 0.844125L3 4.34058Z' fill='#485E4C' />
              </svg>
              <h5>Back</h5>
            </button>
          ) : null}
          {!mobile ? <Text /> : null}
        </div>
        <form className={"r  flex  w-100p flex-c flex-grow-1  " + (!mobile ? "m-t-40vh  " : "m-t-60")} id='form1' name='contact' method='POST' data-netlify='true'>
          <div className={"flex-grow-1 h-100p re flex flex-c "}>
            {mobile ? (
              <div className='m-b-15'>
                {" "}
                <Text />{" "}
              </div>
            ) : null}
            <div className='flex flex-c w-100p h-200 flex-grow-1 h-100p re '>
              <div className={"flex flex-c w-100p h-200 flex-grow-1 h-100p ab fade-1 " + (menu.formSubmitted ? "p-0 p-e-n" : "p-1")}>
                <input type='hidden' name='form-name' value='contact' />
                <input type='text' name='name' placeholder='name*' className='p-t-0-i' required style={{ color: "3F3D3D", fontFamily: "GT Alpina Tw Trial Lt" }} />
                <select name='EnquiryType' required>
                  <option value='' disabled selected>
                    Enquiry Type*
                  </option>
                  <option value='SalesEnquiry'>Sales enquiry</option>
                  <option value='TradeEnquiry'>Trade enquiry</option>
                </select>
                <select name='ProductNo' required>
                  <option value='' disabled selected>
                    Product No*
                  </option>
                  {productsList}
                </select>
                <input type='text' name='email' placeholder='email*' required />
                <input type='text' name='phone' placeholder='Phone*' required />
                <textarea type='text' name='message' className='flex-grow-1 w-100p m-h-30px' placeholder='Message...' />
                <div className='flex flex-r flex-sb'>
                  <p>We’ll get back to you shortly.</p>{" "}
                  <button
                    aria-label='button'
                    onClick={e => {
                      handleSubmit(e)
                    }}
                  >
                    <ButtonHoverNone name='submit' />
                  </button>
                </div>
              </div>
              <div className={"flex flex-c  p-b-120-br fade-1-1 " + (menu.formSubmitted ? "p-1" : "p-0 p-e-n")}>
                <p className='input m-b-20 p-t-0-i'>Your enquiry has been received.</p>
                <button
                  onClick={e => {
                    closeSecondary(true, e)
                  }}
                  type='button'
                >
                  <ButtonHoverNone name='   keep exploring' />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  function openCart() {
    const cartDiv = document.querySelector(".cart")
    const overlay = document.querySelector(".overlay")
    cartDiv?.classList.remove("closed")
    overlay?.classList.remove("hidden")
  }

  return (
    <div className={"fi  l-0 h-100vh t-0  b-0 z-4  w-42 br z-70  t-tran-m-h "} id='menu'>
      {!menu.menuOpen && <button className='cart-btn' onClick={openCart} />}
      <div className='h-100p '>
        <button
          onClick={() => {
            closeSecondary()
          }}
          className={" c-n  back-grey-multi  fi  " + (menu.menuOpen ? "p-5 fade-in w-100vw h-100vh" : " p-0 fade-out-m w-0 h-0")}
        ></button>
        <div id='undo-transition' className={" h-100p b-cream m-w-410-br  flex flex-r  w-100vw  z-100 " + (!menu.menuOpen ? "tran-200p tran-an-c" : "tran-0 tran-an-1-m")}>
          <div id='menu-container' className={"fade-menu-content ab-r-0 z-100 tran-an h-100p w-42 flex flex-c-v flex-c-h b-r-g br re p-15 m-w-42 b-cream menu-br-w-100 re b-grey-br"}>
            <button
              id='menu-button'
              className={" fade-menu-container ab z-4 r-15-br h-100p-br flex-br center-h-br center-v-br t-0-br "}
              onClick={() => {
                setMenu({ type: "openMenu", payload: { menu: true } })
              }}
            >
              <svg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <line x1='12' y1='8.5' x2='-2.87986e-08' y2='8.5' stroke='#3F3D3D' />
                <line x1='12' y1='4.5' x2='-3.26384e-08' y2='4.5' stroke='#3F3D3D' />
                <line x1='12' y1='0.5' x2='-2.87986e-08' y2='0.499998' stroke='#3F3D3D' />
              </svg>
            </button>
            <div className='flex flex-c flex-c-v flex-c-h z-3'>
              <button
                className='re flex flex-center-v flex-center-h'
                onClick={() => {
                  nav("/")
                }}
                style={{ marginRight: "-1px" }}
              >
                <div>
                  <div className='flex flex-c ju-c'>
                    <svg width='55' height='13' viewBox='0 0 55 13' fill='none' xmlns='http://www.w3.org/2000/svg' className='d-n-b-n-m'>
                      <path d='M8.11555 9.63792C8.61665 11.1704 8.84128 11.4525 9.49646 11.5407L9.49646 11.9991L6.04346 11.9991L6.04346 11.5407C6.97655 11.5231 7.37254 11.3115 7.37254 10.712C7.37254 10.4475 7.30342 10.0949 7.1479 9.63645L6.54313 7.83946L2.57319 7.83946L2.02025 9.56592C1.88201 10.0067 1.79561 10.377 1.79561 10.64C1.79561 11.2571 2.19304 11.504 3.21108 11.5392L3.21108 11.9977L-4.36174e-06 11.9977L-4.19501e-06 11.5392C0.552936 11.4687 0.863966 11.0279 1.34635 9.53066L4.19601 0.757301L3.9253 0.00500488L4.90879 0.00500488L8.11555 9.63792ZM2.76326 7.27671L6.35449 7.27671L4.5416 1.81522L2.76326 7.27671Z' fill='#3F3D3D' />
                      <path d='M23.4989 0.019043L23.6544 3.05027L23.136 3.05027C22.8941 1.02406 22.5312 0.512737 20.5801 0.512737L18.5598 0.512737L18.5598 5.64069L20.1827 5.64069C21.4599 5.64069 21.6673 5.41148 21.8055 3.75554L22.3239 3.75554L22.3239 8.01953L21.8055 8.01953C21.6673 6.3636 21.4599 6.13439 20.1827 6.13439L18.5598 6.13439L18.5598 10.0105C18.5598 11.3314 18.9573 11.5077 20.4591 11.543L20.4591 12.0014L16.4014 12.0014L16.4014 11.543C17.454 11.5253 17.6268 11.3138 17.6268 10.1868L17.6268 1.83366C17.6268 0.706688 17.454 0.495105 16.4014 0.477473L16.4014 0.019043L23.4989 0.019043Z' fill='#3F3D3D' />
                      <path d='M53.4478 10.3073C54.0007 11.2418 54.3233 11.4519 54.5998 11.5225L54.5998 11.9809L53.1541 11.9809L49.6665 6.11387L48.1819 6.11387L48.1819 9.98996C48.1819 11.2404 48.3893 11.4872 49.39 11.5225L49.39 11.9809L46.0234 11.9809L46.0234 11.5225C47.0775 11.5048 47.2488 11.2933 47.2488 10.1663L47.2488 1.81462C47.2488 0.687645 47.076 0.476062 46.0234 0.45843L46.0234 0L49.943 0C52.1706 0 53.2405 1.00502 53.2405 2.78438C53.2405 4.25223 52.3779 5.69658 50.7018 6.03159L53.4478 10.3073ZM48.1819 0.493694L48.1819 5.62165L49.6665 5.62165C51.3584 5.62165 52.136 4.28309 52.136 2.83727C52.136 1.28713 51.514 0.493694 49.8911 0.493694L48.1819 0.493694Z' fill='#3F3D3D' />
                      <path d='M37.8102 0.019043L31.8704 0.019043L30.6824 0.019043L30.5586 3.22512L31.077 3.22512C31.4226 0.846275 31.699 0.5289 33.391 0.5289L34.3759 0.5289L34.3759 9.35662C34.3759 11.0831 34.1685 11.5062 32.684 11.5415L32.684 11.9999L36.9836 11.9999L36.9836 11.5415C35.5163 11.5062 35.309 11.0831 35.309 9.35662L35.309 0.5289L36.4653 0.5289C38.0363 0.5289 38.2954 0.846275 38.6065 3.22512L39.1249 3.22512L39.001 0.019043L37.8102 0.019043Z' fill='#3F3D3D' />
                    </svg>
                  </div>
                  <div>
                    <svg width='55' height='13' viewBox='0 0 55 13' fill='none' xmlns='http://www.w3.org/2000/svg' className={"d-n-b fade-menu " + (menu.menuOpen ? "p-0" : "p-1")} style={{ transform: "rotateZ(270deg)" }}>
                      <path d='M8.11555 9.63792C8.61665 11.1704 8.84128 11.4525 9.49646 11.5407L9.49646 11.9991L6.04346 11.9991L6.04346 11.5407C6.97655 11.5231 7.37254 11.3115 7.37254 10.712C7.37254 10.4475 7.30342 10.0949 7.1479 9.63645L6.54313 7.83946L2.57319 7.83946L2.02025 9.56592C1.88201 10.0067 1.79561 10.377 1.79561 10.64C1.79561 11.2571 2.19304 11.504 3.21108 11.5392L3.21108 11.9977L-4.36174e-06 11.9977L-4.19501e-06 11.5392C0.552936 11.4687 0.863966 11.0279 1.34635 9.53066L4.19601 0.757301L3.9253 0.00500488L4.90879 0.00500488L8.11555 9.63792ZM2.76326 7.27671L6.35449 7.27671L4.5416 1.81522L2.76326 7.27671Z' fill='#3F3D3D' />
                      <path d='M23.4989 0.019043L23.6544 3.05027L23.136 3.05027C22.8941 1.02406 22.5312 0.512737 20.5801 0.512737L18.5598 0.512737L18.5598 5.64069L20.1827 5.64069C21.4599 5.64069 21.6673 5.41148 21.8055 3.75554L22.3239 3.75554L22.3239 8.01953L21.8055 8.01953C21.6673 6.3636 21.4599 6.13439 20.1827 6.13439L18.5598 6.13439L18.5598 10.0105C18.5598 11.3314 18.9573 11.5077 20.4591 11.543L20.4591 12.0014L16.4014 12.0014L16.4014 11.543C17.454 11.5253 17.6268 11.3138 17.6268 10.1868L17.6268 1.83366C17.6268 0.706688 17.454 0.495105 16.4014 0.477473L16.4014 0.019043L23.4989 0.019043Z' fill='#3F3D3D' />
                      <path d='M53.4478 10.3073C54.0007 11.2418 54.3233 11.4519 54.5998 11.5225L54.5998 11.9809L53.1541 11.9809L49.6665 6.11387L48.1819 6.11387L48.1819 9.98996C48.1819 11.2404 48.3893 11.4872 49.39 11.5225L49.39 11.9809L46.0234 11.9809L46.0234 11.5225C47.0775 11.5048 47.2488 11.2933 47.2488 10.1663L47.2488 1.81462C47.2488 0.687645 47.076 0.476062 46.0234 0.45843L46.0234 0L49.943 0C52.1706 0 53.2405 1.00502 53.2405 2.78438C53.2405 4.25223 52.3779 5.69658 50.7018 6.03159L53.4478 10.3073ZM48.1819 0.493694L48.1819 5.62165L49.6665 5.62165C51.3584 5.62165 52.136 4.28309 52.136 2.83727C52.136 1.28713 51.514 0.493694 49.8911 0.493694L48.1819 0.493694Z' fill='#3F3D3D' />
                      <path d='M37.8102 0.019043L31.8704 0.019043L30.6824 0.019043L30.5586 3.22512L31.077 3.22512C31.4226 0.846275 31.699 0.5289 33.391 0.5289L34.3759 0.5289L34.3759 9.35662C34.3759 11.0831 34.1685 11.5062 32.684 11.5415L32.684 11.9999L36.9836 11.9999L36.9836 11.5415C35.5163 11.5062 35.309 11.0831 35.309 9.35662L35.309 0.5289L36.4653 0.5289C38.0363 0.5289 38.2954 0.846275 38.6065 3.22512L39.1249 3.22512L39.001 0.019043L37.8102 0.019043Z' fill='#3F3D3D' />
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div id='menu-content' className={"fade-menu-container "}>
            <div className={"m-w-410 br-410   w-100vw ab b-cream h-100vh-br w-100vw-br  br flex-grow-1 flex-c  flex-sb flex flex-c-v z-50 b-r-grey  h-100p "}>
              <div className={"flex flex-r p-r-15 p-l-15 p-t-15  w-100p ab p-b-30 z-50 b-cream " + (mobile ? "flex flex-c-h" : "flex-sb")}>
                <button
                  onClick={() => {
                    nav("/")
                  }}
                  className='flex flex-c ju-c'
                >
                  <svg width='55' className='tran-logo' height='13' viewBox='0 0 55 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M8.11555 9.63792C8.61665 11.1704 8.84128 11.4525 9.49646 11.5407L9.49646 11.9991L6.04346 11.9991L6.04346 11.5407C6.97655 11.5231 7.37254 11.3115 7.37254 10.712C7.37254 10.4475 7.30342 10.0949 7.1479 9.63645L6.54313 7.83946L2.57319 7.83946L2.02025 9.56592C1.88201 10.0067 1.79561 10.377 1.79561 10.64C1.79561 11.2571 2.19304 11.504 3.21108 11.5392L3.21108 11.9977L-4.36174e-06 11.9977L-4.19501e-06 11.5392C0.552936 11.4687 0.863966 11.0279 1.34635 9.53066L4.19601 0.757301L3.9253 0.00500488L4.90879 0.00500488L8.11555 9.63792ZM2.76326 7.27671L6.35449 7.27671L4.5416 1.81522L2.76326 7.27671Z' fill='#3F3D3D' />
                    <path d='M23.4989 0.019043L23.6544 3.05027L23.136 3.05027C22.8941 1.02406 22.5312 0.512737 20.5801 0.512737L18.5598 0.512737L18.5598 5.64069L20.1827 5.64069C21.4599 5.64069 21.6673 5.41148 21.8055 3.75554L22.3239 3.75554L22.3239 8.01953L21.8055 8.01953C21.6673 6.3636 21.4599 6.13439 20.1827 6.13439L18.5598 6.13439L18.5598 10.0105C18.5598 11.3314 18.9573 11.5077 20.4591 11.543L20.4591 12.0014L16.4014 12.0014L16.4014 11.543C17.454 11.5253 17.6268 11.3138 17.6268 10.1868L17.6268 1.83366C17.6268 0.706688 17.454 0.495105 16.4014 0.477473L16.4014 0.019043L23.4989 0.019043Z' fill='#3F3D3D' />
                    <path d='M53.4478 10.3073C54.0007 11.2418 54.3233 11.4519 54.5998 11.5225L54.5998 11.9809L53.1541 11.9809L49.6665 6.11387L48.1819 6.11387L48.1819 9.98996C48.1819 11.2404 48.3893 11.4872 49.39 11.5225L49.39 11.9809L46.0234 11.9809L46.0234 11.5225C47.0775 11.5048 47.2488 11.2933 47.2488 10.1663L47.2488 1.81462C47.2488 0.687645 47.076 0.476062 46.0234 0.45843L46.0234 0L49.943 0C52.1706 0 53.2405 1.00502 53.2405 2.78438C53.2405 4.25223 52.3779 5.69658 50.7018 6.03159L53.4478 10.3073ZM48.1819 0.493694L48.1819 5.62165L49.6665 5.62165C51.3584 5.62165 52.136 4.28309 52.136 2.83727C52.136 1.28713 51.514 0.493694 49.8911 0.493694L48.1819 0.493694Z' fill='#3F3D3D' />
                    <path d='M37.8102 0.019043L31.8704 0.019043L30.6824 0.019043L30.5586 3.22512L31.077 3.22512C31.4226 0.846275 31.699 0.5289 33.391 0.5289L34.3759 0.5289L34.3759 9.35662C34.3759 11.0831 34.1685 11.5062 32.684 11.5415L32.684 11.9999L36.9836 11.9999L36.9836 11.5415C35.5163 11.5062 35.309 11.0831 35.309 9.35662L35.309 0.5289L36.4653 0.5289C38.0363 0.5289 38.2954 0.846275 38.6065 3.22512L39.1249 3.22512L39.001 0.019043L37.8102 0.019043Z' fill='#3F3D3D' />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    closeSecondary()
                  }}
                  className='ab r-15 m-10-br'
                >
                  <h4>[x]</h4>
                </button>
              </div>
              {!mobile ? (
                MenuContent()
              ) : (
                <div className={"fade-in o-s  " + (fadeMenuContent ? "p-1" : "p-0")}>
                  {MenuContent()}
                  {FormContent()}
                </div>
              )}
            </div>

            {!mobile ? FormContent() : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
