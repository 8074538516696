import React, { useContext, useState, useEffect } from "react"
import { MenuContextController } from "./Context/menu"
import "../components/Styles/index.scss"
import Menu from "./ResuableComponents/Menu"
import Location from "./Context/location"
import Scroll from "./ResuableComponents/scroll"
import Loading from "./MediatorComponents/Loading/index.js"
import "../components/ResuableComponents/Menu/index.scss"
import "../components/ResuableComponents/LineGen/LineGen.scss"
import "../components/ResuableComponents/button/button.scss"
import "../components/MediatorComponents/CatSectionsImages/index.scss"
import "../components/MediatorComponents/Footer/index.scss"
import "../components/MediatorComponents/GridImages/index.scss"
import "../components/MediatorComponents/Product/index.scss"
import "../components/MediatorComponents/ProductsLayout/index.scss"
import "../components/MediatorComponents/Slider/index.scss"
import "../components/Layouts/BodyWrapper/index.scss"
import "../components/Layouts/SplitSectionHalf/index.scss"
import "../components/Layouts/SplitSectionToBot/SplitSectionTopBot.scss"
import "../components/ResuableComponents/NextPage/index.scss"
import "../components/MediatorComponents/Loading/index.scss"
import { MobileContext } from "./Context/mobile"
import Cart from "./components/cart"

const Layout = ({ children, location }) => {
  const { menu } = useContext(MenuContextController)
  const { mobile } = useContext(MobileContext)
  const [loadState, setLoadState] = useState(true)
  const [internal, setInternal] = useState(false)
  useEffect(() => {
    if (menu.menuOpen && mobile) {
      document.querySelector("body").style.overflow = "hidden"
      document.querySelector("body").style.height = "100vh"
    } else if (mobile) {
      document.querySelector("body").style.overflow = "unset"
      document.querySelector("body").style.height = "unset"
    }
  }, [menu.menuOpen, mobile])

  const [init, setInit] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (!init) {
        if (document.referrer.indexOf(window.location.host) === -1) {
          setLoadState(true)
        }

        setTimeout(() => {
          setLoadState(false)
        }, 0)

        setInit(true)
      }
    }, 0)
  }, [])
  useEffect(() => {
    if (location?.pathname === "/" && menu.load) {
      document.querySelector("#lines").style.transition = "none"
      document.querySelector("#lines").style.opacity = "0"
    }
  }, [])

  return (
    <>
      <main>
        {location?.pathname === "/" && !menu.load ? <Loading /> : null}
        <Cart />
        <Menu path={location} />
        <Scroll location={location}>
          <Location path={location}>
            <div className={"fade-in " + ((!location?.pathname !== "/" && !menu.load && !loadState) || init ? "p-1" : "p-0")}>
              <div className={"z-3  " + (menu?.bodyFade ? "p-0 tran-p" : "p-1 tran-075")}>{children}</div>
            </div>
          </Location>
        </Scroll>
      </main>
    </>
  )
}

export default Layout
