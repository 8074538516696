import { navigate } from "gatsby-link"
import React, { useEffect,useState,useContext } from "react"
import { LocationContext } from "./location"
import { MenuContextController } from "./menu"

const ChangePage = ({to,children,className,...props}) => {

    const path = useContext(LocationContext).location
    const [location] = useState(path)
    const {setMenu} = useContext(MenuContextController)

    useEffect(() =>{
            setMenu({ type: 'buttonPressed', payload: { bodyFade: false } })
    },[path,setMenu])
    const changePage = () =>{
    
    if (to !== location?.pathname) {
        setMenu({ type: 'buttonPressed', payload: { bodyFade: true } })
        setMenu({type:"loadMenu"})
        setMenu({ type: 'setCurrentPage', payload: { currentPage: to } })
 
        setTimeout(() => {
            navigate(to,{state:{internal:location.pathname}})
        }, 1000)
    }
}
    return (
        <button onClick={() =>{changePage()}} {...props} className={className}>
            {children}         
        </button>
    )
}

export default ChangePage