
import React, { createContext, useEffect, useState, } from 'react'

export const LocationContext = createContext()
const Location = ({ path, children }) => {
    const [location, setLocation] = useState(path)
    useEffect(() => {
        setLocation(path)
    }, [path])

    return (
        <LocationContext.Provider value={{ location }}>
            {children}
        </LocationContext.Provider>
    )
}

export default Location