import React from "react"

const ButtonHoverNone = ({ className, name,svgClass,h4Class,location,slug, ...props }) => {
 
   
    return (
        <div className={" flex flex-r center-v no-hover " + className} {...props} >
            <svg width="3" height="7" viewBox="0 0 3 7" fill="none" xmlns="http://www.w3.org/2000/svg" className={"m-r-15 re move-s-r " + svgClass}>
                <path d="M3 3.80322L-4.70644e-08 6.83431L2.17922e-07 0.772134L3 3.80322Z" fill="#485E4C" />
            </svg>
            <h4>
                {name}
            </h4>
        </div>
    )
}


export default ButtonHoverNone